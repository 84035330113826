import React, { useState } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { SinglePost } from '../components/SinglePost'
import { ContentSlider } from '../components/Slider'
import { LanguageSwitcher } from '../components/LanguageSwitcher'
import { SocialIcons } from '../components/SocialIcons'
import { parseBlogPosts } from '../utils/helpers'
import { Styled } from '../styles/blog.styles'
import { graphql } from 'gatsby'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const { language } = useI18next()

  const { t } = useTranslation()
  const content = t('blogPost', { returnObjects: true })

  const posts = parseBlogPosts(edges)
    .filter((post) => post.status && post.lang === language)
    .sort((a, b) => b.ogDate.localeCompare(a.ogDate))


  return (
    <Layout>
      <SEO
        title="Blog"
        description="Fique a par das últimas novidades da Next2U"
      />
      <Styled.Main style={{ backgroundImage: `url(${content.background})` }}>
        <LanguageSwitcher />

        <SocialIcons isColumn />

        <h1>Blog</h1>

        <div className="blog-slider">
          <ContentSlider slidesToShow={posts.length > 1 ? 2 : 1}>
            {posts.map((post) => (
              <SinglePost key={post.id} post={post} />
            ))}
          </ContentSlider>
        </div>

        <div className="blog-posts">
          {posts.map((post) => (
            <SinglePost key={post.path} post={post} />
          ))}
        </div>
      </Styled.Main>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query ($language: String!) {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY", locale: $language)
            ogDate: date
            path
            title
            img
            status
            description
            lang
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: { in: ["home", "green", "global"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
