import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.black};
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: top center;
  padding: ${({ theme }) => `20rem ${theme.spacingM} ${theme.spacingL}`};

  .language-switcher {
    position: absolute;
    top: 50vh;
    right: ${({ theme }) => theme.spacingS};
  }

  .social-icons {
    position: absolute;
    top: 85vh;
    right: ${({ theme }) => theme.spacingS};
  }

  h1 {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 5rem;
    line-height: 6rem;
    letter-spacing: 0.4rem;
    text-align: center;
    text-transform: uppercase;
  }

  .blog-slider {
    margin-top: ${({ theme }) => theme.spacingM};

    .slick-slide > div {
      margin: 0 ${({ theme }) => theme.spacingM};
    }
  }

  .blog-posts {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    gap: ${({ theme }) => theme.spacingL};
    margin-top: ${({ theme }) => theme.spacingM};
    padding-bottom: ${({ theme }) => theme.spacingXL};

    article {
      max-width: 60rem;
    }

    a:nth-child(even) .single-post {
      transform: translateY(10rem);
    }
  }

  @media screen and (max-width: 1500px) {
    .blog-slider article {
      height: 40rem;
    }
  }

  @media screen and (max-width: 1250px) {
    .blog-slider .slick-slide > div {
      margin: 0 ${({ theme }) => theme.spacingS};
    }
  }

  @media screen and (max-width: 1150px) {
    .blog-slider article {
      height: 35rem;
    }

    .blog-posts a:nth-child(even) .single-post {
      transform: none;
    }
  }

  @media screen and (max-width: 1000px) {
    .blog-slider {
      display: none;
    }

    .blog-posts {
      display: flex;
    }
  }

  @media screen and (max-width: 450px) {
    padding: ${({ theme }) => `20rem ${theme.spacingS} ${theme.spacingL}`};
  }
`

export const Styled = {
  Main,
}
